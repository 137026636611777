.masonry-card {
    overflow: hidden;
    width: 18rem;
    border: none;
}
.masonry-img {
    width: 100%;
    height: 12rem;
    object-fit: scale-down; /* Ajusta la imagen para que se centre bien */
}
.masonry-col {
    padding: 1rem;
}
.myproduct-img {
    width: 100%;
    height: 8rem;
    object-fit: scale-down; /* Ajusta la imagen para que se centre bien */
}
.galery-card {
    overflow: hidden;
    /*width: 18rem;
    min-width: 18rem;*/
    border: none;
}
.galery-img {
    width: 100%;
    height: 12rem;
    object-fit: scale-down; /* Ajusta la imagen para que se centre bien */
}
.galery-col {
    padding: 1rem;
}
.galery-product-name {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 2rem; /* fallback */
    max-height: 4rem; /* fallback */
    min-height: 4rem; /* a veces hay productos de una sola línea, por eso lo pongo, para que todos tengan la misma altura */
    -webkit-line-clamp: 2; /* number of lines to show */
}
.blacked{ font-weight: 600; }
.bg-gray { background-color: #F0F0F0; }
.pr-0 { padding-right: 0px; }
/*   .ml-0\.5 => className='ml-0.5'  */
.ml-0\.5 { margin-left: 0.5rem; }
.mr-0\.5 { margin-right: 0.5rem; }
.ml-1 { margin-left: 1rem; }
.mr-1 { margin-right: 1rem; }
.ml-1\.5 { margin-left: 1.5rem; }
.mr-1\.5 { margin-right: 1.5rem; }
.ml-2 { margin-left: 2rem; }
.mr-2 { margin-right: 2rem; }
.ml-2\.5 { margin-left: 2.5rem; }
.mr-2\.5 { margin-right: 2.5rem; }
.ml-3 { margin-left: 3rem; }
.mr-3 { margin-right: 3rem; }
.ml-3\.5 { margin-left: 3.5rem; }
.mr-3\.5 { margin-right: 3.5rem; }
.ml-4 { margin-left: 4rem; }
.mr-4 { margin-right: 4rem; }
.ml-4\.5 { margin-left: 4.5rem; }
.mr-4\.5 { margin-right: 4.5rem; }
.ml-5 { margin-left: 5rem; }
.mr-5 { margin-right: 5rem; }

:root {
  --primary-color: #338AD1;
  --hover-primary-color: #2679bd;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color); 
}
.btn-primary:hover {
  background-color: var(--hover-primary-color);
  border-color: var(--hover-primary-color); 
}
.btn-outline-primary {
  /*background-color: var(--primary-color);*/
  border-color: var(--primary-color); 
  color: var(--primary-color);
}
.btn-outline-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color); 
}
.page-item.active .page-link {
  /*color: #fff !important;*/
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.page-item.active:hover .page-link:hover {
  /*color: #fff !important;*/
  background-color: var(--hover-primary-color);
  border-color: var(--hover-primary-color);
}
.bg-primary {
  opacity: 1;
  background-color: var(--hover-primary-color) !important;
}